import React from 'react';
import { Link } from 'react-router-dom';
import { PoseGroup } from 'react-pose';
import {VerticalTwistContainer} from '../../../utils/pose_containers';
import ContentFrame from '../../content_frame';
import * as routes from '../../../constants';
import DefaultInput, {SelectOption} from '../../../utils/default_input';
import ModelTable, {Property} from '../../../utils/model_table';
import ConfirmationWindow from '../../confirmation_window';
import ContextPopup from '../../../components/context_popup';
import OverlayWindow from '../../../components/overlay_window';
import ExpandableSection from '../../../components/expandable_section';
import DefaultMenuButton from '../../../components/default_menu_button';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../../utils/default_section';
import {getModels, postModel, deleteModel, setUrlParameters, getLocalDateIsoString, getLocalTimeIsoString, getAsLocalDateString, getAsLocalDate, getAsLocalDatetime, getPhoneText, getAbbreviatedName} from '../../../utils/functions';
import * as permissions from '../../../permissions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID,
        APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID,
        DEFAULT_UNIT_TYPE} from '../../../constants';
import './appointment_schedule_list.scss';

class AppointmentScheduleList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let initialDate = queryParameters.get('initial_date');

    if(!initialDate) {
      initialDate = new Date();
      const dayId = initialDate.getDay();

      if (dayId > 0) {
        initialDate.setDate(initialDate.getDate() - dayId);
      }
    }
    else {
      initialDate = getAsLocalDate(initialDate);
    }

    let responsibleId = queryParameters.get('responsible_id');
    let roomId = queryParameters.get('room_id');
    let viewMode = queryParameters.get('view_mode');

    if(!responsibleId) {
      responsibleId = null;
    }
    else {
      responsibleId = parseInt(responsibleId);
    }

    if(!roomId) {
      roomId = null;
    }
    else {
      roomId = parseInt(roomId);
    }

    if(!viewMode) {
      viewMode = 1;
    }
    else {
      viewMode = parseInt(viewMode);
    }

    this.state = {
      loadingData: true,
      appointment_rooms: [],
      responsible_options: [],
      appointment_schedules: [],
      students: [],
      all_services: true,
      serviceIdsSelected: [],
      serviceFilter: "",
      initialDate,
      viewModeSelected: (this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_APPOINTMENT_SCHEDULE_PERMISSION_ID)) ? viewMode : 2,
      selectedRoomId: roomId,
      selectedResponsibleId: (this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_APPOINTMENT_SCHEDULE_PERMISSION_ID)) ? responsibleId : this.props.userId,
      physicalEvaluationEmailsSent: false,
      nutritionalEvaluationEmailsSent: false,
      sendPhysicalEvaluationEmails: false,
      sendNutritionalEvaluationEmails: false,
      deleteSchedule: null,
      deleteAppointmentId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      popupSelectedSchedule: null,
      popupTarget: null,
      availableAppointmentSelected: null,
      screenWidth: window.innerWidth,
      calendarContentWidth: null,
    };

    this.calendarHeaderDayFormat = new Intl.DateTimeFormat('pt-BR', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    this.calendarDayFormat = new Intl.DateTimeFormat('pt-BR', {
      weekday: 'short',
    });

    this.calendarHourMargin = 1;

    this.calendarContent = null;

    this.calendarContentRef = (element) => {
      this.calendarContent = element;

      if(element) {
        this.setState({calendarContentWidth: element.scrollWidth});
      }
    };
  }

  getCalendarMinSlotHeight() {
    return 4;
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  async getServices() {
    return await getModels(routes.SERVICES_GET_API);
  }

  async getAppointmentRooms() {
    if (this.props.userPermissionIds.includes(permissions.VIEW_APPOINTMENT_ROOM_PERMISSION_ID)) {
      return await getModels(routes.APPOINTMENT_ROOMS_GET_API);
    }

    return [];
  }

  async getAppointmentResponsibles() {
    if (this.isDefaultUnit() &&
        this.props.userPermissionIds.includes(permissions.VIEW_APPOINTMENT_SCHEDULE_PERMISSION_ID) &&
        this.props.userPermissionIds.includes(permissions.VIEW_NUTRITIONIST_PERMISSION_ID) &&
        this.props.userPermissionIds.includes(permissions.VIEW_COACH_PERMISSION_ID)) {
      return await getModels(routes.APPOINTMENT_RESPONSIBLE_OPTIONS_GET_API);
    }

    return [];
  }

  async getAppointmentSchedules() {
    const finalDate = new Date(this.state.initialDate);
    finalDate.setDate(finalDate.getDate() + 7);

    const parameters = {
      initial_date: `${getLocalDateIsoString(this.state.initialDate)}T00:00`,
      final_date: `${getLocalDateIsoString(finalDate)}T00:00`
    };

    const list = await getModels(setUrlParameters(routes.APPOINTMENT_SCHEDULES_GET_API, parameters));

    if (list) {
      list.sort((a, b) => a.start_at.localeCompare(b.start_at));
    }

    return list
  }

  async getStudents() {
    const parameters = {active_only: true};

    return await getModels(setUrlParameters(routes.STUDENTS_GET_API, parameters));
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.initialDate !== this.state.initialDate) {
      await this.reloadAppointmentSchedules();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    const update = {screenWidth: window.innerWidth};

    if(this.calendarContent !== null) {
      update.calendarContentWidth = this.calendarContent.scrollWidth;
    }

    this.setState(update);
  }

  async reloadAppointmentSchedules() {
    this.setState({
      loadingData: true
    });

    const update = {
      loadingData: false
    };

    update.appointment_schedules = await this.getAppointmentSchedules();

    this.setState(update);
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    let appointment_schedules = this.getAppointmentSchedules();
    let appointment_rooms = this.getAppointmentRooms();
    let responsible_options = this.getAppointmentResponsibles();
    let services = this.getServices();

    appointment_schedules = await appointment_schedules;
    appointment_rooms = await appointment_rooms;
    responsible_options = await responsible_options;
    services = await services;

    responsible_options.sort((a, b) => a.name.localeCompare(b.name));
    services.sort((a, b) => a.name.localeCompare(b.name));

    let selectedRoomId = this.state.selectedRoomId;

    if (appointment_rooms) {
      appointment_rooms.sort((a, b) => a.name.localeCompare(b.name));

      if (selectedRoomId !== null) {
        if (!appointment_rooms.find((room) => room.id === selectedRoomId)) {
          selectedRoomId = appointment_rooms[0].id;
        }
      }
      else {
        const filteredRooms = appointment_rooms.filter((room) => {
          return room.is_active || appointment_schedules.some((entry) => entry.appointment_room_id === room.id);
        });

        if (filteredRooms.length === 1) {
          selectedRoomId = filteredRooms[0].id;
        }
      }
    }

    this.setState({
      loadingData: false,
      appointment_schedules,
      appointment_rooms,
      responsible_options,
      selectedRoomId,
      services,
    });
  }

  onSendPhysicalEvaluationScheduleEmails() {
    this.setState({
      sendPhysicalEvaluationEmails: true,
      sendNutritionalEvaluationEmails: false,
      deleteSchedule: null,
      deleteAppointmentId: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onSendNutritionalEvaluationScheduleEmails() {
    this.setState({
      sendPhysicalEvaluationEmails: false,
      sendNutritionalEvaluationEmails: true,
      deleteSchedule: null,
      deleteAppointmentId: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onDeleteScheduleEntry(entry) {
    this.setState({
      sendPhysicalEvaluationEmails: false,
      sendNutritionalEvaluationEmails: false,
      deleteSchedule: entry,
      deleteAppointmentId: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onDeleteAppointmentEntry(entryId) {
    this.setState({
      sendPhysicalEvaluationEmails: false,
      sendNutritionalEvaluationEmails: false,
      deleteSchedule: null,
      deleteAppointmentId: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmation() {
    this.setState({
      sendPhysicalEvaluationEmails: false,
      sendNutritionalEvaluationEmails: false,
      deleteSchedule: null,
      deleteAppointmentId: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.deleteSchedule != null) {
      try{
        if(await deleteModel(`${routes.APPOINTMENT_SCHEDULE_DELETE_API}${this.state.deleteSchedule.id}`)) {
          this.reloadAppointmentSchedules();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 208:
                if (error.message.includes('Some users have an invalid email.')) {
                  errorDescription = 'Email(s) de aluno(s) inválido(s): Não foi possível enviar o email notificando ' +
                                     'sobre o cancelamento da avaliação aos alunos.';

                  for(let parameter of error.parameters) {
                    if (parameter.name.includes('usernames')) {
                      errorDescription += `Alunos com email inválido: ${parameter.value.join('; ')}.`
                    }
                  }

                  errorDescription += 'Neste caso, realize o cancelamento individualmente para cada ' +
                                      'agendamento e entre em contato manualmente com os alunos cujo email se encontra inválido.'
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.deleteAppointmentId != null) {
      try{
        if(await deleteModel(`${routes.APPOINTMENT_DELETE_API}${this.state.deleteAppointmentId}`)) {
          this.reloadAppointmentSchedules();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 208:
                if (error.message.includes('User selected have an invalid email')) {
                  errorDescription = 'Email de aluno inválido: Não foi possível enviar o email notificando ' +
                                     'o aluno sobre o cancelamento da avaliação. Entre em contato manualmente ' +
                                     'com o aluno para notificar sobre o cancelamento e para corrigir seu email inválido.';
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.sendPhysicalEvaluationEmails || this.state.sendNutritionalEvaluationEmails) {
      let service_plan_ids;

      if(this.state.all_services) {
        service_plan_ids = [...this.state.services.map((service) => service.id)];
      }
      else {
        service_plan_ids = [...this.state.serviceIdsSelected];
      }

      let update = {};

      let appointment_type_id;

      if (this.state.sendPhysicalEvaluationEmails) {
        appointment_type_id = APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID;
        update.physicalEvaluationEmailsSent = true;
      }
      else {
        appointment_type_id = APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID;
        update.nutritionalEvaluationEmailsSent = true;
      }

      try {
        if(await postModel(routes.NOTIFICATION_APPOINTMENT_DELIVER_SCHEDULE_EMAILS_POST, {service_plan_ids, appointment_type_id})) {
          this.setState(update);
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 208:
                if (error.message.startsWith('No appointment schedule available')) {
                  errorDescription = 'Nenhum período com vaga disponível para agendamento encontrado.';
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        update = {
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        };

        if (this.state.sendPhysicalEvaluationEmails) {
          update.physicalEvaluationEmailsSent = false;
        }
        else {
          update.nutritionalEvaluationEmailsSent = false;
        }

        this.setState(update);

        return;
      }
    }

    this.setState({
      sendPhysicalEvaluationEmails: false,
      sendNutritionalEvaluationEmails: false,
      deleteSchedule: null,
      deleteAppointmentId: null,
      confirmInProgress: false,
    });
  }

  async onCreateAppointment(user_id) {
    if (this.state.availableAppointmentSelected === null) {
      return;
    }

    this.setState({
      confirmInProgress: true
    });

    const data = {
      scheduled_at: this.state.availableAppointmentSelected.scheduled_at.slice(0, 16),
      finish_at: this.state.availableAppointmentSelected.finish_at.slice(0, 16),
      user_id: user_id,
      appointment_schedule_id: this.state.availableAppointmentSelected.appointment_schedule.id
    };

    try{
      if(await postModel(routes.APPOINTMENT_POST_API, data)) {
        this.setState({
          loadingData: true,
          confirmInProgress: false,
          availableAppointmentSelected: null
        });

        await this.reloadAppointmentSchedules();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 106:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'Another appointment is already scheduled for selected period':
                    errorDescription = 'Um novo agendamento acabou de ser criado para este horário';

                    break;
                  default:
                }
              }

              break;
            // case 208:
            //   if (error.message.includes('This entry cannot be deleted due to its essencial associacions')) {
            //     errorDescription = 'Esta entrada não pode ser removida por conter informações nutricionais importantes.';
            //   }
            //
            //   break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.reloadAppointmentSchedules();

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }
  }

  getResponsibleOptions() {
    const responsible_options = this.state.responsible_options.map((entry) => SelectOption(entry.id, entry.name));

    if (this.state.selectedResponsibleId !== null) {
      return responsible_options;
    }

    return [
      SelectOption('', 'Selecione um responsável'),
      ...responsible_options
    ];
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    if (name === 'selectedResponsibleId' && value.length > 0) {
      value = parseInt(value);

      this.props.history.replace(setUrlParameters(routes.APPOINTMENT_SCHEDULE_LIST_PATH, {
        initial_date: getLocalDateIsoString(this.state.initialDate),
        responsible_id: value,
        view_mode: this.state.viewModeSelected,
        room_id: this.state.selectedRoomId
      }));
    }

    const update = {[name]: value};

    this.setState(update);
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.deleteSchedule !== null) {
        if (this.state.deleteSchedule.appointments.length > 0) {
          return 'Cancelando período';
        }
        else {
          return 'Deletando período';
        }
      }
      else if(this.state.deleteAppointmentId !== null) {
        return 'Cancelando atendimento';
      }
      else if(this.state.sendPhysicalEvaluationEmails) {
        return 'Enviando emails de AV. FÍSICA';
      }
      else if(this.state.sendNutritionalEvaluationEmails) {
        return 'Enviando emails de AV. NUTRICIONAL';
      }

      return 'Unknown';
    }
    else if(this.state.confirmFailed) {
      if(this.state.deleteSchedule !== null) {
        if (this.state.deleteSchedule.appointments.length > 0) {
          return 'Falha ao cancelar período';
        }
        else {
          return 'Falha ao deletar período';
        }
      }
      else if(this.state.deleteAppointmentId !== null) {
        return 'Falha ao cancelar atendimento';
      }
      else if(this.state.sendPhysicalEvaluationEmails) {
        return 'Falha ao enviar emails de AV. FÍSICA';
      }
      else if(this.state.sendNutritionalEvaluationEmails) {
        return 'Falha ao enviar emails de AV. NUTRICIONAL';
      }
      else if(this.state.availableAppointmentSelected !== null) {
        return 'Falha ao agendar horário';
      }

      return 'Unknown fail';
    }

    if(this.state.deleteSchedule !== null) {
      if (this.state.deleteSchedule.appointments.length > 0) {
        return 'Cancelar período';
      }
      else {
        return 'Deletar período';
      }
    }
    else if(this.state.deleteAppointmentId !== null) {
      return 'Cancelar atendimento';
    }
    else if(this.state.sendPhysicalEvaluationEmails) {
      return 'Enviar emails de AV. FÍSICA';
    }
    else if(this.state.sendNutritionalEvaluationEmails) {
      return 'Enviar emails de AV. NUTRICIONAL';
    }

    return 'Unknown';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteSchedule !== null) {
      if (this.state.deleteSchedule.appointments.length > 0) {
        return 'Uma notificação de cancelamento será enviada aos alunos com atendimentos ' +
               'agendados no período e, em seguida, todos os dados vinculados ao período serão apagados.';
      }
      else {
        return 'Todos os dados relacionados ao período serão removidos.';
      }
    }
    else if(this.state.deleteAppointmentId !== null) {
      return 'Todos os dados relacionados ao atendimento serão removidos. Tentaremos enviar um email notificando o aluno sobre o cancelamento.';
    }
    else if(this.state.sendPhysicalEvaluationEmails) {
      return 'Um email contendo um link de agendamento será enviado para todos os alunos ' +
             'habilitados à realizar a AVALIAÇÃO FÍSICA. Certifique-se também de notificar manualmente os alunos cujo email ' +
             'se encontra inválido ou que não esteja visualizando nossas notificações.';
    }
    else if(this.state.sendNutritionalEvaluationEmails) {
      return 'Um email contendo um link de agendamento será enviado para todos os alunos ' +
             'habilitados à realizar a AVALIAÇÃO NUTRICIONAL. Certifique-se também de notificar manualmente os alunos cujo email ' +
             'se encontra inválido ou que não esteja visualizando nossas notificações.';
    }

    return 'Unknown';
  }

  getConfirmationWindowConfirmText() {
    if(this.state.deleteSchedule != null) {
      if (this.state.deleteSchedule.appointments.length > 0) {
        return 'Cancelar período';
      }
      else {
        return 'Deletar período';
      }
    }
    else if(this.state.deleteAppointmentId !== null) {
      return 'Confirmar cancelamento';
    }
    else if(this.state.sendPhysicalEvaluationEmails) {
      return 'Enviar emails';
    }
    else if(this.state.sendNutritionalEvaluationEmails) {
      return 'Enviar emails';
    }

    return 'Unknown';
  }

  onSelectViewMode(viewMode) {
    this.props.history.replace(setUrlParameters(routes.APPOINTMENT_SCHEDULE_LIST_PATH, {
      initial_date: getLocalDateIsoString(this.state.initialDate),
      responsible_id: this.state.selectedResponsibleId,
      view_mode: viewMode,
      room_id: this.state.selectedRoomId
    }));

    this.setState({
      viewModeSelected: viewMode
    })
  }

  onSelectRoom(roomId) {
    this.props.history.replace(setUrlParameters(routes.APPOINTMENT_SCHEDULE_LIST_PATH, {
      initial_date: getLocalDateIsoString(this.state.initialDate),
      responsible_id: this.state.selectedResponsibleId,
      view_mode: this.state.viewModeSelected,
      room_id: roomId
    }));

    this.setState({
      selectedRoomId: roomId
    })
  }

  onChangeCurrentWeek(weekStep) {
    const initialDate = new Date(this.state.initialDate);

    initialDate.setDate(initialDate.getDate() + (weekStep * 7));

    this.props.history.replace(setUrlParameters(routes.APPOINTMENT_SCHEDULE_LIST_PATH, {
      initial_date: getLocalDateIsoString(initialDate),
      responsible_id: this.state.selectedResponsibleId,
      view_mode: this.state.viewModeSelected,
      room_id: this.state.selectedRoomId
    }));

    this.setState({
      initialDate
    })
  }

  async onSelectTimeSlot(slot) {
    const update = {availableAppointmentSelected: slot};

    if (this.state.students.length <= 0) {
      this.setState({loadingData: true})

      update.students = await this.getStudents();
      update.loadingData = false;
    }

    this.setState(update);
  }

  onToggleScheduleInfo(target, schedule) {
    if (this.state.popupTarget === target) {
      this.setState({
        popupSelectedSchedule: null,
        popupTarget: null,
      });
    }
    else {
      this.setState({
        popupSelectedSchedule: schedule,
        popupTarget: target,
      });
    }
  }

  getViewModeOptions() {
    return [
      (
        <button
          key="appointment_schedule:selector:view_mode:1"
          className="appointment-schedule-list__selector__button"
          disabled={this.state.viewModeSelected === 1}
          onClick={() => this.onSelectViewMode(1)}
        >

          Por sala

        </button>
      ),
      (
        <button
          key="appointment_schedule:selector:view_mode:2"
          className="appointment-schedule-list__selector__button"
          disabled={this.state.viewModeSelected === 2}
          onClick={() => this.onSelectViewMode(2)}
        >

          Por responsável

        </button>
      )
    ];
  }

  getViewModeSelectors() {
    switch (this.state.viewModeSelected) {
      case 1:
        const filteredRooms = this.state.appointment_rooms.filter((room) => {
          return room.is_active || this.state.selectedRoomId === room.id || this.state.appointment_schedules.some((entry) => entry.appointment_room_id === room.id);
        });

        const roomOptions = filteredRooms.map((room) => {
          const selected = room.id === this.state.selectedRoomId;

          return (
            <button
              key={`appointment_schedule:selector:room:${room.id}`}
              className="appointment-schedule-list__selector__button"
              disabled={selected}
              onClick={() => this.onSelectRoom(room.id)}
            >

              {room.name}

            </button>
          );
        });

        return (
          <React.Fragment>

            <DefaultSubSectionTitle
              className="appointment-schedule-list__selector__header"
              icon={<i className="fas fa-list"></i>}
              text="Sala selecionada"
            />

            <div className="appointment-schedule-list__selector">

              {roomOptions}

            </div>

          </React.Fragment>
        );
      case 2:
        return (
          <DefaultInput
            name="selectedResponsibleId"
            label="Responsável pelo atendimento"
            type="select"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.selectedResponsibleId || ''}
            options={this.getResponsibleOptions()}
          />
        );
      default:
    }
  }

  getCalendarDaySchedule(appointment_schedules, isodate, minCalendarTime, calendarHourHeight) {
    let minDayTime = minCalendarTime;
    let offsetSum = 0;

    return appointment_schedules.filter((entry) => entry.start_at.startsWith(isodate)).map((entry) => {
      const startAtHours = parseFloat(entry.start_at.slice(11, 13)) + (parseFloat(entry.start_at.slice(14, 16)) / 60);
      const endAtHours = parseFloat(entry.end_at.slice(11, 13)) + (parseFloat(entry.end_at.slice(14, 16)) / 60);

      const scheduleHeight = calendarHourHeight * (endAtHours - startAtHours);
      offsetSum += calendarHourHeight * (startAtHours - minDayTime)
      const schedulePosition = offsetSum;

      if (minDayTime < endAtHours) {
        minDayTime = endAtHours;
      }

      let time_slots = [
        ...entry.available_time_slots.map((time) => {
          const finish_at = getAsLocalDatetime(time, false);
          finish_at.setMinutes(finish_at.getMinutes() + entry.default_entry_duration);

          return {
            id: null,
            scheduled_at: time,
            finish_at: `${getLocalDateIsoString(finish_at)}T${getLocalTimeIsoString(finish_at)}`,
            appointment_schedule: entry
          };
        }),
        ...entry.appointments
      ]

      time_slots.sort((a, b) => a.scheduled_at.localeCompare(b.scheduled_at));

      time_slots = time_slots.map((slot) => {
        const isAvailable = slot.id === null;

        let slotHeight;

        if (isAvailable) {
          slotHeight = calendarHourHeight * entry.default_entry_duration / 60;
        }
        else {
          const scheduledAtHours = parseFloat(slot.scheduled_at.slice(11, 13)) + (parseFloat(slot.scheduled_at.slice(14, 16)) / 60);
          const finishAtHours = parseFloat(slot.finish_at.slice(11, 13)) + (parseFloat(slot.finish_at.slice(14, 16)) / 60);

          slotHeight = calendarHourHeight * (finishAtHours - scheduledAtHours);
        }

        return (
          <div
            key={`appointment_schedule_list:calendar:schedule:${entry.id}:slot:${slot.scheduled_at}`}
            className={`appointment-schedule-list__calendar__day__schedule__time-slot${isAvailable ? '--available' : ''}`}
            style={{
              height: `${slotHeight}em`
            }}
          >

            <div className="appointment-schedule-list__calendar__day__schedule__time-slot__wrapper">

              <h5 className="appointment-schedule-list__calendar__day__schedule__time-slot__label">{slot.scheduled_at.slice(11, 16)}</h5>

              {isAvailable ? (
                <button
                  className="appointment-schedule-list__calendar__day__schedule__time-slot__add-button"
                  onClick={() => this.onSelectTimeSlot(slot)}
                >

                  <i className="fa-solid fa-plus appointment-schedule-list__calendar__day__schedule__time-slot__add-button__icon"></i>
                  Adicionar

                </button>
              ) : (
                <div className={`appointment-schedule-list__calendar__day__schedule__time-slot__appointment${slot.attended === null ? '' : '--attended'}`}>

                  <p className="appointment-schedule-list__calendar__day__schedule__time-slot__appointment__name">{getAbbreviatedName(slot.user.name)}</p>
                  <p className="appointment-schedule-list__calendar__day__schedule__time-slot__appointment__email">{slot.user.email}</p>

                  {slot.attended === null &&
                    <button
                      className="appointment-schedule-list__calendar__day__schedule__time-slot__appointment__cancel-button"
                      onClick={() => this.onDeleteAppointmentEntry(slot.id)}
                    >

                      <i className="fa-solid fa-xmark appointment-schedule-list__calendar__day__schedule__time-slot__appointment__cancel-button__icon"></i>

                    </button>
                  }

                </div>
              )}

            </div>

          </div>
        );
      });

      return (
        <div
          key={`appointment_schedule_list:calendar:schedule:${entry.id}`}
          className="appointment-schedule-list__calendar__day__schedule"
          style={{
            background: entry.appointment_type.color,
            height: `${scheduleHeight}em`,
            top: `${schedulePosition}em`
          }}
        >

          <div className="appointment-schedule-list__calendar__day__schedule__action-container">

            <button
              className="appointment-schedule-list__calendar__day__schedule__action-button"
              onClick={(event) => {
                this.onToggleScheduleInfo(event.target, entry);
              }}
            >

              <i className="fa-solid fa-circle-info appointment-schedule-list__calendar__day__schedule__action-button__icon"></i>

            </button>

            {this.props.userPermissionIds.includes(permissions.EDIT_APPOINTMENT_SCHEDULE_PERMISSION_ID) &&
              <Link
                className="appointment-schedule-list__calendar__day__schedule__action-button--blue"
                to={`${routes.APPOINTMENT_SCHEDULE_EDIT_PATH}${entry.id}`}
              >

                  <i className="fas fa-edit"></i>

              </Link>
            }

            {(this.props.userPermissionIds.includes(permissions.DELETE_APPOINTMENT_SCHEDULE_PERMISSION_ID) && entry.appointments.every((entry) => entry.attended === null)) &&
              <button
                className="appointment-schedule-list__calendar__day__schedule__action-button--red"
                onClick={() => this.onDeleteScheduleEntry(entry)}
              >

                <i className="far fa-trash-alt"></i>

              </button>
            }

          </div>

          <div className="appointment-schedule-list__calendar__day__schedule__time-slot-container">

            {time_slots}

          </div>

        </div>
      );
    });
  }

  getCalendar() {
    let appointment_schedules = [];

    let emptyMessage;

    switch (this.state.viewModeSelected) {
      case 1:
        if (this.state.selectedRoomId === null) {
          return (
            <p className="appointment-schedule-list__alert-text">

              <i className="fas fa-exclamation appointment-schedule-list__alert-text__icon"></i>
              <span className="appointment-schedule-list__alert-text__text">
                Sala não selecionada
              </span>

            </p>
          );
        }

        appointment_schedules = this.state.appointment_schedules.filter((entry) => entry.appointment_room_id === this.state.selectedRoomId);

        emptyMessage = 'Nenhum atendimento cadastrado para a sala e período selecionados';
        break;
      case 2:
        if (this.state.selectedResponsibleId === null) {
          return (
            <p className="appointment-schedule-list__alert-text">

              <i className="fas fa-exclamation appointment-schedule-list__alert-text__icon"></i>
              <span className="appointment-schedule-list__alert-text__text">
                Responsável não selecionado
              </span>

            </p>
          );
        }

        appointment_schedules = this.state.appointment_schedules.filter((entry) => entry.responsible_id === this.state.selectedResponsibleId);

        emptyMessage = 'Nenhum atendimento cadastrado para o responsável e período selecionados';
        break;
      default:
        return (
          <p className="appointment-schedule-list__alert-text">

            <i className="fas fa-exclamation appointment-schedule-list__alert-text__icon"></i>
            <span className="appointment-schedule-list__alert-text__text">
              UNDEFINED ERROR
            </span>

          </p>
        );
    }

    let minCalendarTime = null;
    let maxCalendarTime = null;

    let minSlotDuration = 1;

    for (const entry of appointment_schedules) {
      if (minSlotDuration > (entry.default_entry_duration / 60)) {
        minSlotDuration = entry.default_entry_duration / 60;
      }

      for (const appointmentEntry of entry.appointments) {
        const scheduledAtHours = parseFloat(appointmentEntry.scheduled_at.slice(11, 13)) + (parseFloat(appointmentEntry.scheduled_at.slice(14, 16)) / 60);
        const finishAtHours = parseFloat(appointmentEntry.finish_at.slice(11, 13)) + (parseFloat(appointmentEntry.finish_at.slice(14, 16)) / 60);

        const duration = finishAtHours - scheduledAtHours;

        if (minSlotDuration > duration) {
          minSlotDuration = duration;
        }
      }

      const startAt = entry.start_at.slice(11, 16);
      const endAt = entry.end_at.slice(11, 16);

      if (minCalendarTime === null || minCalendarTime > startAt) {
        minCalendarTime = startAt;
      }
      if (maxCalendarTime === null || maxCalendarTime < endAt) {
        maxCalendarTime = endAt;
      }
    }

    const calendarHourHeight = this.getCalendarMinSlotHeight() / minSlotDuration;

    if (minCalendarTime !== null && maxCalendarTime !== null) {
      minCalendarTime = parseInt(minCalendarTime.slice(0, 2));
      maxCalendarTime = Math.ceil(parseFloat(maxCalendarTime.slice(0, 2)) + (parseFloat(maxCalendarTime.slice(3, 5)) / 60));
    }

    let dayEntries = [];

    const currentDate = new Date(this.state.initialDate);
    const initialDayText = this.calendarHeaderDayFormat.format(currentDate);

    const calenterTimeDifference = (minCalendarTime !== null && maxCalendarTime !== null) ? (maxCalendarTime - minCalendarTime) : 0;

    const todayIsodate = getLocalDateIsoString(new Date());

    for (let i=0; i < 7; ++i) {
      const currentDateIsodate = getLocalDateIsoString(currentDate);
      const isToday = todayIsodate === currentDateIsodate;

      dayEntries.push(
        <div
          key={`appointment_schedule_list:calendar:day:${i}`}
          className={`appointment-schedule-list__calendar__day${isToday ? '--today' : ''}`}
        >

          <div
            className="appointment-schedule-list__calendar__day__header"
            style={{
              height: `${this.calendarHourMargin}em`
            }}
          >

            <h4 className="appointment-schedule-list__calendar__day__header__text">

              <span className="appointment-schedule-list__calendar__day__header__day-name">{this.calendarDayFormat.format(currentDate).replace('.', '')}</span>
              <span className="appointment-schedule-list__calendar__day__header__day-number">{currentDate.getDate()}</span>

            </h4>

          </div>

          <div
            className="appointment-schedule-list__calendar__day__content"
            style={{height: `${calendarHourHeight * calenterTimeDifference}em`}}
          >

            {this.getCalendarDaySchedule(appointment_schedules, currentDateIsodate, minCalendarTime, calendarHourHeight)}

          </div>

        </div>
      );

      if (i < 6) {
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    const hourDivisions = [];

    if (minCalendarTime !== null && maxCalendarTime !== null) {
      for (let j=minCalendarTime; j <= maxCalendarTime; ++j) {
        let divisionHeight = calendarHourHeight;

        if (j === maxCalendarTime) {
          divisionHeight = this.calendarHourMargin;
        }

        hourDivisions.push(
          <div
            key={`appointment_schedule_list:calendar:hour_division:${j}`}
            className="appointment-schedule-list__calendar__hour-division"
            style={{height: `${divisionHeight}em`}}
          >

            <p className="appointment-schedule-list__calendar__hour-division__value">{j.toLocaleString('pt-BR', {minimumIntegerDigits: 2, useGrouping: false})}:00</p>

          </div>
        );
      }
    }

    const finalDayText = this.calendarHeaderDayFormat.format(currentDate);

    const hourDivisionStyle = {};

    if (this.state.calendarContentWidth !== null) {
      hourDivisionStyle.width = `${this.state.calendarContentWidth}px`
    }

    return (
      <div className="appointment-schedule-list__calendar">

        <div className="appointment-schedule-list__calendar__date-controls">

          <button
            className="appointment-schedule-list__calendar__date-controls__button"
            onClick={() => this.onChangeCurrentWeek(-1)}
          >

            <i className="fa-solid fa-chevron-left appointment-schedule-list__calendar__date-controls__button__icon"></i>

          </button>

          <h3 className="appointment-schedule-list__calendar__date-controls__header">{initialDayText} - {finalDayText}</h3>

          <button
            className="appointment-schedule-list__calendar__date-controls__button"
            onClick={() => this.onChangeCurrentWeek(1)}
          >

            <i className="fa-solid fa-chevron-right appointment-schedule-list__calendar__date-controls__button__icon"></i>

          </button>

        </div>

        <div className="appointment-schedule-list__calendar__hr"></div>

        <div
          className="appointment-schedule-list__calendar__main-container"
          style={{
            paddingBottom: `${this.calendarHourMargin}em`
          }}
        >

          <div ref={this.calendarContentRef} className="appointment-schedule-list__calendar__day-entries-container">
            <div
              className="appointment-schedule-list__calendar__hour-division__wrapper"
              style={hourDivisionStyle}
            >

              {hourDivisions}

            </div>

            <div className="appointment-schedule-list__calendar__hour-division-spacer">
            </div>

            {dayEntries}
          </div>


        </div>

        {(minCalendarTime === null || maxCalendarTime === null) &&
          <p className="appointment-schedule-list__calendar__alert-text">

            <i className="fas fa-exclamation appointment-schedule-list__calendar__alert-text__icon"></i>
            <span className="appointment-schedule-list__calendar__alert-text__text">
              {emptyMessage}
            </span>

          </p>
        }

      </div>
    );
  }

  getPopupContent() {
    if(this.state.popupSelectedSchedule === null) {
      return null;
    }

    return (
      <div className="appointment-schedule-list__calendar__day__schedule__info">

        <h5
          className="appointment-schedule-list__calendar__day__schedule__info__title"
          style={{
            color: this.state.popupSelectedSchedule.appointment_type.color
          }}
        >

          {this.state.popupSelectedSchedule.appointment_type.name}

        </h5>

        {this.isDefaultUnit() &&
          <p className="appointment-schedule-list__calendar__day__schedule__info__entry">

            <span className="appointment-schedule-list__calendar__day__schedule__info__entry__label">Responsável:</span>
            {this.state.popupSelectedSchedule.responsible.name}

          </p>
        }
        <p className="appointment-schedule-list__calendar__day__schedule__info__entry">

          <span className="appointment-schedule-list__calendar__day__schedule__info__entry__label">Sala:</span>
          {this.state.popupSelectedSchedule.appointment_room.name}

        </p>

      </div>
    );
  }

  getAvailableAppointmentOverlayTitle() {
    if (this.state.availableAppointmentSelected === null) {
      return '';
    }

    return (
      <span>
        Agendar: <span style={{color: this.state.availableAppointmentSelected.appointment_schedule.appointment_type.color}}>{getAsLocalDateString(this.state.availableAppointmentSelected.scheduled_at)} {this.state.availableAppointmentSelected.scheduled_at.slice(11, 16)}</span>
      </span>
    );
  }

  getStudentProperties() {
    const properties = [
      Property('name', 'Nome', <i className="fas fa-id-card"></i>),
      Property('email', 'E-mail', <i className="fas fa-envelope"></i>),
      Property('phone', 'Telefone', <i className="fas fa-phone"></i>, {getDataText: (entry) => getPhoneText(entry.phone)})
    ];

    return properties;
  }

  getStudentActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <button
          className="appointment-schedule-list__activate-button"
          onClick={() => {
            this.onCreateAppointment(entry.id);
          }}
        >

          <i className="far fa-check-square appointment-schedule-list__activate-button__icon"></i>
          Agendar

        </button>

      </div>
    );
  }

  getOverlayIcon() {
    if(this.state.confirmInProgress) {
      return (
        <VerticalTwistContainer key="appointment_schedule_list_confirm_icon">
          <i className="fas fa-spinner appointment-schedule-list__send-emails-window__loading-icon"></i>
        </VerticalTwistContainer>
      );
    }

    return (
      <VerticalTwistContainer key="appointment_schedule_list_alert_icon">
        <i className="fas fa-exclamation-circle appointment-schedule-list__send-emails-window__alert-icon"></i>
      </VerticalTwistContainer>
    );
  }

  onClickService(service_id) {
    const serviceIdsSelected = [...this.state.serviceIdsSelected];

    if(serviceIdsSelected.includes(service_id)) {
      serviceIdsSelected.splice(serviceIdsSelected.indexOf(service_id), 1);
    }
    else {
      serviceIdsSelected.push(service_id);
    }

    this.setState({serviceIdsSelected});
  }

  getServiceOptions() {
    if(!this.state.services) {
      return null;
    }

    const filteredServices = this.state.services.filter((service) => service.name.toLocaleLowerCase().includes(this.state.serviceFilter.toLocaleLowerCase()));

    if(filteredServices.length <= 0) {
      return null;
    }

    return filteredServices.map((service) => {
      const selected = this.state.serviceIdsSelected.includes(service.id);

      return (
        <div
          key={`appointment_schedule_list:service:${service.id}`}
          className={`appointment-schedule-list__send-emails-window__service-plan-filter__option${this.state.all_services || !selected ? '--disabled': ''}`}
        >

          <button
            className="appointment-schedule-list__send-emails-window__service-plan-filter__option__check-button"
            onClick={() => this.onClickService(service.id)}
            disabled={this.state.all_services}
          >

            {(this.state.all_services || selected) &&
              <i className="fas fa-check"></i>
            }

          </button>

          <p className="appointment-schedule-list__send-emails-window__service-plan-filter__option__text">

            {service.name}

          </p>

        </div>
      );
    });
  }

  render() {
    return (
      <React.Fragment>

        <OverlayWindow
          className="appointment-schedule-list__overlay"
          visible={this.state.availableAppointmentSelected != null}
          actions={(
            <div className="appointment-schedule-list__overlay__action-container">

              <DefaultMenuButton
                className="appointment-schedule-list__overlay__action-button"
                onClick={() => this.setState({
                  availableAppointmentSelected: null
                })}
                text="Cancelar"
              />

            </div>
          )}
        >

          <header className="appointment-schedule-list__overlay__header">

            <h3 className="appointment-schedule-list__overlay__header__title">
              {this.getAvailableAppointmentOverlayTitle()}
            </h3>

          </header>

          <hr className="appointment-schedule-list__horizontal-rule" />

          <div className="appointment-schedule-list__overlay__content">

            <ModelTable
              properties={this.getStudentProperties()}
              getActions={(entry) => this.getStudentActions(entry)}
              data={this.state.students}
              initialOrderBy="name"
              enableMinRowHeight={true}
            >
            </ModelTable>

          </div>

        </OverlayWindow>

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.APPOINTMENT_SCHEDULE_LIST_PATH,
              text: "Calendário de atendimento"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Calendário de atendimento"
          loading={this.state.loadingData}
        >
          <DefaultSection
            className="appointment-schedule-list"
            title="Calendário de atendimento"
          >

            {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_APPOINTMENT_SCHEDULE_PERMISSION_ID)) &&
              <React.Fragment>

                <DefaultSubSectionTitle
                  className="appointment-schedule-list__selector__header"
                  icon={<i className="fas fa-list"></i>}
                  text="Modo de visualização"
                />

                <div className="appointment-schedule-list__selector">

                  {this.getViewModeOptions()}

                </div>

                <HorizontalRule />

                {this.getViewModeSelectors()}

                <HorizontalRule />

              </React.Fragment>
            }

            <div className="appointment-schedule-list__list-actions">

              {this.props.userPermissionIds.includes(permissions.SEND_ALL_APPOINTMENT_SCHEDULE_NOTIFICATION_PERMISSION_ID) &&
                <button
                  className="model-table__default-button"
                  disabled={this.state.physicalEvaluationEmailsSent}
                  onClick={(event) => this.onSendPhysicalEvaluationScheduleEmails()}
                >

                  <i className="fas fa-envelope"></i> {this.state.physicalEvaluationEmailsSent ? 'Emails enviados' : 'Enviar emails de agendamento de AV. FÍSICA'}

                </button>
              }

              {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.SEND_ALL_APPOINTMENT_SCHEDULE_NOTIFICATION_PERMISSION_ID)) &&
                <button
                  className="model-table__default-button"
                  disabled={this.state.nutritionalEvaluationEmailsSent}
                  onClick={(event) => this.onSendNutritionalEvaluationScheduleEmails()}
                >

                  <i className="fas fa-envelope"></i> {this.state.nutritionalEvaluationEmailsSent ? 'Emails enviados' : 'Enviar emails de agendamento de AV. NUTRICIONAL'}

                </button>
              }


              {(this.props.userPermissionIds.includes(permissions.ADD_APPOINTMENT_SCHEDULE_PERMISSION_ID) &&
                this.props.userPermissionIds.includes(permissions.VIEW_NUTRITIONIST_PERMISSION_ID) &&
                this.props.userPermissionIds.includes(permissions.VIEW_COACH_PERMISSION_ID)) &&
                <Link
                  className="model-table__default-button"
                  to={routes.APPOINTMENT_SCHEDULE_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar novo período

                </Link>
              }

            </div>

            {this.getCalendar()}

          </DefaultSection>

        </ContentFrame>

        <ContextPopup
          className="appointment-schedule-list__calendar__day__schedule__info-wrapper"
          targetElement={this.state.popupTarget}
          content={this.getPopupContent()}
        />

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteSchedule !== null ||
                   this.state.deleteAppointmentId !== null ||
                   (this.state.sendPhysicalEvaluationEmails && this.state.confirmFailed) ||
                   (this.state.sendNutritionalEvaluationEmails && this.state.confirmFailed) ||
                   (this.state.availableAppointmentSelected !== null && this.state.confirmFailed)}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <OverlayWindow
          className="appointment-schedule-list__send-emails-window"
          visible={(this.state.sendPhysicalEvaluationEmails || this.state.sendNutritionalEvaluationEmails) && !this.state.confirmFailed}
          actions={!this.state.confirmInProgress ?
            (
              <div className="appointment-schedule-list__send-emails-window__action-container">

                <button
                  className="appointment-schedule-list__send-emails-window__cancel-button"
                  onClick={() => this.onCancelConfirmation()}
                >

                  Cancelar

                </button>

                <button
                  className="appointment-schedule-list__send-emails-window__confirm-button"
                  onClick={() => this.onAcceptConfirmation()}
                  disabled={this.state.confirmInProgress}
                >

                  {this.getConfirmationWindowConfirmText()}

                </button>

              </div>
            ):
            null
          }
        >

          <header className="appointment-schedule-list__send-emails-window__header">

            <PoseGroup>

              {this.getOverlayIcon()}

            </PoseGroup>

            <h2 className={`appointment-schedule-list__send-emails-window__header__title${this.state.confirmInProgress ? '--pulsing' : ''}`}>
              {this.getConfirmationWindowTitle()}
            </h2>

          </header>

          <hr className="appointment-schedule-list__send-emails-window__horizontal-rule" />

          <div
            className="appointment-schedule-list__send-emails-window__reference-container"
          >
            <p className="appointment-schedule-list__send-emails-window__description">

              {this.getConfirmationWindowDescription()}

            </p>

            {!this.state.confirmInProgress &&
              <React.Fragment>

                <hr className="appointment-schedule-list__send-emails-window__horizontal-rule" />

                <ExpandableSection
                  iconClass="fas fa-filter"
                  title="Filtrar por serviço"
                >

                  <h3 className="appointment-schedule-list__send-emails-window__service-plan-filter__instructions">Selecione os planos desejados:</h3>

                  <DefaultInput
                    name="all_services"
                    label="Selecionar todos:"
                    type="toggle"
                    isHorizontal={true}
                    activeText="Sim"
                    inactiveText="Não"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.all_services}
                    disabled={this.state.confirmInProgress}
                  />

                  <DefaultInput
                    name="serviceFilter"
                    label="Busca rápida:"
                    type="text"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.serviceFilter}
                    autoComplete="off"
                    disabled={this.state.confirmInProgress}
                  />

                  <div className="appointment-schedule-list__send-emails-window__service-plan-filter__options-container">

                    {this.getServiceOptions()}

                  </div>

                </ExpandableSection>

              </React.Fragment>
            }

          </div>

        </OverlayWindow>

      </React.Fragment>
    );
  }
}

export default AppointmentScheduleList;
